 html {
      box-sizing: border-box;
    }

    *, *::before, *::after {
      box-sizing: inherit;
      padding: 0;
      margin: 0;
    }

    .app {
      font-family: Lobster;
      max-width: 100% ;
      height: auto;
      margin: 0 auto;
      padding: 20px;
    }

    nav ul {
      list-style: none;
      display: flex;
      background-color: black;
      margin-bottom: 20px;
    }

    nav ul li {
      padding: 20px;
    }

    nav ul li a {
      font-family: Lobster;
      color: yellow;
      text-decoration: none;
    }

    .current {
      border-bottom: 4px solid DeepPink;
    }
  
    p {
      margin-bottom: 15px;
    }

    .photo-responsive {
         height : auto;
        max-width : 100%}

    .fixed-panel {
        min-height: 100px;
        max-height: 100px;
    }

    .video-responsive{
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
    }

    .video-responsive iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }

    table, th, td {
       font-family: Lobster;
       color: black;
       text-decoration: none;    
    }

    a {
      color: black;
    }
